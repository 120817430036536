import { Column, TextOption } from "@/interface/config";
import _ from "lodash";

export const cellReadOnlyRenderer = (data: any, column: Column | null) => {
    if(!column) return data;
    switch (column.type) {
        case 'text':
            const castedOption = column.options as TextOption; 
            return castedOption.isHashed ? '******' : data;
        case 'checkbox':
            return data === 'true' ? 'true' : 'false';
        case 'relation':
            if(!data || data.length === 0) return "";
            return _.isArray(data) ? data.map(d => d.id).join(",") : data.id;
        default:
            return data;
    }
}