import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";
import Loading from "@/custom/Loading/loading";
import { useParams } from "react-router-dom";
import { del, get, post, put } from "@/lib/rest";
import { Check, Pencil, Plus, Trash2 } from "lucide-react";
import { set } from "lodash";

const SettingModal = ({ setSettingModal }: { setSettingModal: (a: boolean) => void }) => {
    const [domains, setDomains] = useState([]);
    const [domain, setDomain] = useState("");
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [loading, setLoading] = useState(false);
    const [createNew, setCreateNew] = useState(false);

    useEffect(() => {
        fetchDomains();
    }, []);

    const { projectId } = useParams();

    const onClickDomain = (clickedDomain: any) => {
        setDomain(clickedDomain.domain_name);
        setSelectedDomain(clickedDomain.id);
        setCreateNew(false);
    }

    const onClickCreate = async () => {
        try {
            await post({ url: `/api/project/${projectId}/domain`, data: { domain } });
            setDomain("");
            setSelectedDomain(null);
            setCreateNew(false);
            fetchDomains();
        } catch (error) {
            console.log(error);
        }
    };

    const onClickUpdate = async () => {
        try {
            await put({ url: `/api/project/${projectId}/domain/${selectedDomain}`, data: { domain } });
            fetchDomains();
            setSelectedDomain(null);
        } catch (error) {
            console.log(error);
        }
    };

    const onClickDelete = async () => {
        try {
            await del({ url: `/api/project/${projectId}/domain/${selectedDomain}`, data: {} });
            fetchDomains();
            setSelectedDomain(null);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDomains = async () => {
        setLoading(true);
        try {
            const response = await get({ url: `/api/project/${projectId}/domain` });
            setDomains(response.data.domains);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="absolute left-0 top-0 z-20 flex h-screen w-screen items-center justify-center overflow-hidden bg-slate-950 bg-opacity-70 backdrop-blur-sm">
            <div
                className="grid min-h-[230px] w-[40vw] min-w-[332px] gap-4 justify-self-center"
            >
                <Card className="flex w-full justify-start">
                    {!loading ? (
                        <CardHeader className="w-full">
                            <CardTitle className="w-full flex justify-between">
                                <p>Domains</p>
                                <Button
                                    variant="secondary"
                                    className="flex items-center justify-start p-1"
                                    onClick={() => {
                                        setCreateNew(true);
                                        setDomain("");
                                        setSelectedDomain(null);
                                    }}
                                >
                                    <Plus className="h-4 w-6" />
                                </Button>
                            </CardTitle>
                            <CardContent className="px-0">
                                {domains.map((domainItem: any) => (
                                    <div key={domainItem.id} className="flex w-full justify-between">
                                        {
                                            selectedDomain === domainItem.id ?
                                                <>
                                                    <Input
                                                        name="domain_name"
                                                        type="domain_name"
                                                        placeholder="Domain"
                                                        value={domain}
                                                        onChange={(e) => {
                                                            setDomain(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                    <Button
                                                        variant="ghost"
                                                        className="flex items-center justify-start p-1"
                                                        onClick={() => onClickUpdate()}
                                                    >
                                                        <Pencil className="h-4 w-6" />
                                                    </Button>
                                                    <Button
                                                        variant="ghost"
                                                        className="flex items-center justify-start text-red-600 p-1"
                                                        onClick={() => onClickDelete()}
                                                    >
                                                        <Trash2 className="h-4 w-6" />
                                                    </Button>
                                                </>
                                                :
                                                <Label className="m-4 cursor-pointer" onClick={() => onClickDomain(domainItem)}>{domainItem.domain_name}</Label>
                                        }
                                    </div>
                                ))}
                                {
                                    createNew && (
                                        <div className="grid w-full gap-2 py-4">
                                            <div className="flex">
                                                <Input
                                                    name="domain_name"
                                                    type="domain_name"
                                                    placeholder="Domain"
                                                    value={domain}
                                                    onChange={(e) => {
                                                        setDomain(e.target.value);
                                                    }}
                                                    required
                                                />
                                                <Button
                                                    variant="ghost"
                                                    className="flex items-center justify-start p-1 ml-2 bg-green-300"
                                                    onClick={() => onClickCreate()}
                                                >
                                                    <Check className="h-4 w-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </CardContent>
                            <div className="flex w-full justify-end">
                                <div className="space-x-2">
                                    <Button
                                        variant="outline"
                                        type="button"
                                        onClick={() => {
                                            setSettingModal(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                    ) : (
                        <Loading />
                    )}
                </Card>
            </div>
        </div>
    );
};

export default SettingModal;
