import { useEffect, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { Node, Panel } from "@xyflow/react";

export const PropertyPanel = ({
  node,
  updateNode,
}: {
  node: Node | undefined | null;
  updateNode: any;
}) => {
  const [script, setScript] = useState<any>("");
  const [label, setLabel] = useState<any>(node?.data.label || "");

  useEffect(() => {
    update();
  }, [script, label]);

  useEffect(() => {
    setLabel(node?.data.label || "");
    setScript(node?.data.code || "");
  }, [node]);

  function handleEditorChange(value: any, event: any) {
    setScript(value);
  }

  function update() {
    if (!node) return;
    const newNode = { ...node };
    newNode.data.label = label;
    newNode.data.code = script;
    updateNode(node);
  }

  return (
    <Panel position="bottom-right">
      <aside className="z-50 m-2 h-72 w-[calc(100vw-300px)] rounded-md border-slate-300 bg-slate-100 px-4 py-4">
        {node && node.type === "trigger" && (
          <>
            <input
              className="mb-2 mr-auto w-full px-4 py-2"
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <Editor
              height="200px"
              defaultLanguage="json"
              value={script}
              onChange={handleEditorChange}
              defaultValue=""
            />
          </>
        )}
        {node && node.type === "query" && (
          <>
            <input
              className="mb-2 mr-auto w-full px-4 py-2"
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <Editor
              height="200px"
              defaultLanguage="sql"
              value={script}
              onChange={handleEditorChange}
              defaultValue="-- SQL comment"
            />
          </>
        )}
        {node && node.type === "script" && (
          <>
            <input
              className="mb-2 mr-auto w-full px-4 py-2"
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <Editor
              height="200px"
              defaultLanguage="javascript"
              value={script}
              onChange={handleEditorChange}
              defaultValue="// add javascript below"
            />
          </>
        )}
      </aside>
    </Panel>
  );
};
