import { Braces, CalendarClock, File, FileDigit, FileType2, Image, Key, Link2, Mail, Phone, SquareCheck, SquareMousePointer } from "lucide-react"
import { SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select"

export const renderDataTypeSelect = () => {
    return (
        <>
            <SelectTrigger>
                <SelectValue placeholder="Select a Type" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="text">
                        <div className="flex items-center space-x-2">
                            <FileType2 className="h-4 w-4" />
                            <p> Text</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="email">
                        <div className="flex items-center space-x-2">
                            <Mail className="h-4 w-4" />
                            <p> Email</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="url">
                        <div className="flex items-center space-x-2">
                            <Link2 className="h-4 w-4" />
                            <p> URL</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="phoneNumber">
                        <div className="flex items-center space-x-2">
                            <Phone className="h-4 w-4" />
                            <p> PhoneNumber</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="number">
                        <div className="flex items-center space-x-2">
                            <FileDigit className="h-4 w-4" />
                            <p> Number</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="checkbox">
                        <div className="flex items-center space-x-2">
                            <SquareCheck className="h-4 w-4" />
                            <p> Checkbox</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="select">
                        <div className="flex items-center space-x-2">
                            <SquareMousePointer className="h-4 w-4" />
                            <p> Select</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="date">
                        <div className="flex items-center space-x-2">
                            <CalendarClock className="h-4 w-4" />
                            <p> Date + Time</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="relation">
                        <div className="flex items-center space-x-2">
                            <Key className="h-4 w-4" />
                            <p> Relation</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="image">
                        <div className="flex items-center space-x-2">
                            <Image className="h-4 w-4" />
                            <p> Image</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="file">
                        <div className="flex items-center space-x-2">
                            <File className="h-4 w-4" />
                            <p> File</p>
                        </div>
                    </SelectItem>
                    <SelectItem value="json">
                        <div className="flex items-center space-x-2">
                            <Braces className="h-4 w-4" />
                            <p> JSON</p>
                        </div>
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </>
    )
}

interface FieldTypeToIconMap {
    [key: string]: JSX.Element | null;
}

const fieldTypeToIconMap: FieldTypeToIconMap = {
    text: <FileType2 className="h-4 w-4" />,
    email: <Mail className="h-4 w-4" />,
    url: <Link2 className="h-4 w-4" />,
    phoneNumber: <Phone className="h-4 w-4" />,
    number: <FileDigit className="h-4 w-4" />,
    checkbox: <SquareCheck className="h-4 w-4" />,
    select: <SquareMousePointer className="h-4 w-4" />,
    date: <CalendarClock className="h-4 w-4" />,
    relation: <Key className="h-4 w-4" />,
    image: <Image className="h-4 w-4" />,
    file: <File className="h-4 w-4" />,
    json: <Braces className="h-4 w-4" />
  };

export function getIconByFieldType(fieldType: string | number) {
    return fieldTypeToIconMap[fieldType] || null;
}

export const dataTypeInputMap: any = {
    "text": [
        {
            label: "Min Length",
            key: "min",
            type: "number",
            placeholder: "Min Length",
            render: "textBox"
        },
        {
            label: "Max Length",
            key: "max",
            type: "number",
            placeholder: "Max Length",
            render: "textBox"
        },
        {
            label: "Pattern",
            key: "pattern",
            type: "text",
            placeholder: "Pattern",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            label: "Is Rich Text",
            key: "isRichText",
            type: "checkbox",
            placeholder: "Is Rich Text",
            render: "toggle"
        },
        {
            label: "Is Unique",
            key: "isUnique",
            type: "checkbox",
            placeholder: "Is Unique",
            render: "toggle"
        },
        {
            label: "Is Hashed",
            key: "isHashed",
            type: "checkbox",
            placeholder: "Is Hashed",
            render: "toggle"
        }
    ],
    "email": [
        {
            label: "Pattern",
            key: "pattern",
            type: "text",
            placeholder: "Pattern",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            label: "Is Unique",
            key: "isUnique",
            type: "checkbox",
            placeholder: "Is Unique",
            render: "toggle"
        }
    ],
    "url": [
        {
            label: "Pattern",
            key: "pattern",
            type: "text",
            placeholder: "Pattern",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            label: "Is Unique",
            key: "isUnique",
            type: "checkbox",
            placeholder: "Is Unique",
            render: "toggle"
        }
    ],
    "phoneNumber": [
        {
            label: "Pattern",
            key: "pattern",
            type: "text",
            placeholder: "Pattern",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            label: "Is Unique",
            key: "isUnique",
            type: "checkbox",
            placeholder: "Is Unique",
            render: "toggle"
        }
    ],
    "number": [
        {
            label: "Min Length",
            key: "min",
            type: "number",
            placeholder: "Min Length",
            render: "textBox"
        },
        {
            label: "Max Length",
            key: "max",
            type: "number",
            placeholder: "Max Length",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            render: "empty"
        },
        {
            label: "Has Decimal",
            key: "hasDecimal",
            type: "checkbox",
            placeholder: "Has Decimal",
            render: "toggle"
        }
    ],
    "checkbox": [
        {
            label: "Default",
            key: "default",
            type: "checkbox",
            placeholder: "Default",
            render: "toggle"
        }
    ],
    "select": [
        {
            label: "Select Options",
            key: "selectOptions",
            type: "text",
            placeholder: "Select Options",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        },
        {
            label: "Has Multiple",
            key: "hasMultiple",
            type: "checkbox",
            placeholder: "Has Multiple",
            render: "toggle"
        }
    ],
    "date": [
        {
            label: "Min",
            key: "min",
            type: "date",
            placeholder: "Min",
            render: "textBox"
        },
        {
            label: "Max",
            key: "max",
            type: "date",
            placeholder: "Max",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "date",
            placeholder: "Default",
            render: "textBox"
        },
        {
            render: "empty"
        }
    ],
    "relation": [
        {
            render: "empty"
        },
        {
            label: "Table",
            key: "tableId",
            type: "text",
            placeholder: "Select a table",
            render: "select"
        },
        {
            label: "Has Multiple",
            key: "hasMultiple",
            type: "checkbox",
            placeholder: "Has Multiple",
            render: "toggle"
        },
        {
            label: "Back Column",
            key: "backColumnName",
            type: "text",
            placeholder: "Back-column name",
            render: "textBox"
        },
        {
            label: "Has Multiple",
            key: "hasMultipleBack",
            type: "checkbox",
            placeholder: "Has Multiple Back",
            render: "toggle"
        },
        {
            label: "IsParent",
            key: "isParent",
            type: "checkbox",
            placeholder: "Is Parent",
            render: "toggle"
        }
    ],
    "image": [
        {
            label: "Max File Size",
            key: "maxFileSize",
            type: "number",
            placeholder: "Max File Size",
            render: "textBox"
        },
        {
            label: "File Types",
            key: "fileTypes",
            type: "text",
            placeholder: "File Types",
            render: "textBox"
        },
        {
            label: "Has Multiple",
            key: "hasMultiple",
            type: "checkbox",
            placeholder: "Has Multiple",
            render: "toggle"
        },
        {
            label: "Is Protected",
            key: "isProtected",
            type: "checkbox",
            placeholder: "Is Protected",
            render: "toggle"
        }
    ],
    "file": [
        {
            label: "Max File Size",
            key: "maxFileSize",
            type: "number",
            placeholder: "Max File Size",
            render: "textBox"
        },
        {
            label: "File Types",
            key: "fileTypes",
            type: "text",
            placeholder: "File Types",
            render: "textBox"
        },
        {
            label: "Has Multiple",
            key: "hasMultiple",
            type: "checkbox",
            placeholder: "Has Multiple",
            render: "toggle"
        },
        {
            label: "Is Protected",
            key: "isProtected",
            type: "checkbox",
            placeholder: "Is Protected",
            render: "toggle"
        }
    ],
    "json": [
        {
            label: "Nesting",
            key: "nesting",
            type: "number",
            placeholder: "Nesting",
            render: "textBox"
        },
        {
            label: "Default",
            key: "default",
            type: "text",
            placeholder: "Default",
            render: "textBox"
        }
    ]
}