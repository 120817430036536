import React from "react";
import { Panel } from "@xyflow/react";
import { Button } from "@/components/ui/button";

export const WorkflowPanel = ({
  workflows,
  newWorkflow,
  selectWorkflow,
}: {
  workflows: any;
  newWorkflow: () => void;
  selectWorkflow: (workflow: any) => void;
}) => {
  return (
    <Panel position="top-left" className="h-[calc(100%-100px)]">
      <aside className="m-2 flex h-full w-60 flex-col justify-between rounded-md border-slate-300 bg-slate-100 px-4 py-4">
        <div className="h-full w-full rounded-md bg-slate-50 p-4">
          {workflows.map((workflow: any, index: number) => (
            <p
              key={index}
              onClick={() => selectWorkflow(workflow)}
              className="cursor-pointer text-left"
            >
              {workflow.name}
            </p>
          ))}
        </div>
        <Button
          className="mr-2 mt-4 w-fit bg-green-400 font-bold text-slate-800"
          onClick={() => {
            newWorkflow();
          }}
        >
          New
        </Button>
      </aside>
    </Panel>
  );
};
