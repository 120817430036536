import React from "react";
import NavComponent from "@/custom/Navigation/NavComponent";
import { Outlet } from "react-router-dom";
import SettingModal from "@/components/setttings/SettingModal";

const Project = () => {
  const [settingsModal, setSettingsModal] = React.useState(false);
  return (
    <div className="h-full w-full">
      <NavComponent setSettingModal={setSettingsModal}/>
      <Outlet />
      {settingsModal && (
        <SettingModal
          setSettingModal={setSettingsModal}
        />
      )}
    </div>
  );
};

export default Project;
