import { useEffect, useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Column, RelationOption, Table, TextOption } from "@/interface/config";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { dataTypeInputMap, renderDataTypeSelect } from "./form-ui";
import { Switch } from "../ui/switch";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import _ from "lodash";

export const ColumnUpdate = ({ selectedTable, selectedColumn, setSelectedColumn }: { selectedTable: Table, selectedColumn: Column, setSelectedColumn: (column: Column) => void }) => {
    const [column, setColumn] = useState<Column>(selectedColumn);
    const { config, handleColumnChange } = useDatabaseConfig();

    useEffect(() => {
        document.getElementById("fieldName")?.focus();
    }, []);

    useEffect(() => {
        setSelectedColumn(column);
    }, [column]);

    function handleChange(key: string, value: any) {
        if (column == null) return;
        const { table: updatedTable } = handleColumnChange(selectedTable, column, key, value)
        setColumn(updatedTable.columns.filter((col) => col.id === column.id)[0]);
    }

    return (
        <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2 flex flex-col space-y-2">
                <Label className="w-fit">
                    Order
                </Label>
                <Input
                    type="number"
                    id={`order`}
                    placeholder="Order"
                    value={column?.order || 0}
                    onChange={(e) =>
                        handleChange(
                            "order",
                            e.target.value,
                        )
                    }
                />
            </div>
            <div className="col-span-2 flex flex-col space-y-2">
                <Label htmlFor={`fieldName`} className="w-fit">
                    Field Name
                </Label>
                <Input
                    type="text"
                    id={`fieldName`}
                    placeholder="Name"
                    value={column?.name || ""}
                    onChange={(e) =>
                        handleChange(
                            "name",
                            e.target.value,
                        )
                    }
                />
            </div>
            <div className="col-span-2 flex flex-col space-y-2">
                <Label className="w-fit">Field Type</Label>
                <Select
                    defaultValue="text"
                    value={column?.type || "text"}
                    onValueChange={(value: string) =>
                        handleChange(
                            "type",
                            value,
                        )
                    }
                >
                    {renderDataTypeSelect()}
                </Select>
            </div>
            {column?.options && dataTypeInputMap[column?.type || "text"].map((input: any) => (
                input.render === "textBox" ?
                    <div className="col-span-2 flex flex-col space-y-2">
                        <Label className="w-fit">
                            {input.label}
                        </Label>
                        <Input
                            key={`${column.type}-${input.key}`}
                            type="text"
                            placeholder={input.placeholder}
                            value={
                                (column?.options as any)[input.key]
                            }
                            onChange={(e) =>
                                handleChange(
                                    input.key,
                                    e.target.value,
                                )
                            }
                        />
                    </div> : input.render === "toggle" ?
                        <div className="col-span-1 flex flex-col space-y-2">
                            <Label className="w-fit">
                                {input.label}
                            </Label>
                            <Switch
                                key={`${column.type}-${input.key}`}
                                checked={
                                    (column?.options as any)[input.key]
                                }
                                onCheckedChange={(
                                    checked: boolean,
                                ) =>
                                    handleChange(
                                        input.key,
                                        checked
                                    )
                                }
                            />
                        </div> : input.render === "select" ?
                            <div className="col-span-2 flex flex-col space-y-2">
                                <Label className="w-fit">
                                    {input.label}
                                </Label>
                                <Select
                                    value={
                                        _.find(config.tables, {
                                            id: (column.options as RelationOption)?.tableId
                                        })?.id
                                    }
                                    onValueChange={(value: string) => {
                                        handleChange(
                                            "tableId",
                                            value,
                                        );
                                    }}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a Table" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {config.tables
                                                .filter(
                                                    (tab) =>
                                                        selectedTable.id !== tab.id
                                                )
                                                .map((tab) => (
                                                    <SelectItem
                                                        key={tab.id}
                                                        value={tab.id}
                                                    >
                                                        {tab.name}
                                                    </SelectItem>
                                                ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div> :
                            <div className="col-span-2 flex flex-col space-y-2"></div>
            ))}
            <div className="col-span-1 flex flex-col space-y-2">
                <Label className="w-fit">
                    Required
                </Label>
                <Switch
                    checked={
                        column?.options?.isRequired
                    }
                    onCheckedChange={(
                        checked: boolean,
                    ) =>
                        handleChange(
                            "isRequired",
                            checked
                        )
                    }
                />
            </div>
            <div className="col-span-1 flex flex-col space-y-2">
                <Label className="w-fit">
                    Hidden
                </Label>
                <Switch
                    checked={
                        column?.options?.isHidden
                    }
                    onCheckedChange={(
                        checked: boolean,
                    ) =>
                        handleChange(
                            "isHidden",
                            checked
                        )
                    }
                />
            </div>
        </div>
    )
}