import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  ChevronDown,
  Loader2,
  Pencil,
  Plus,
  Trash2,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Column, RelationOption, Table as TableConfig } from "@/interface/config";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { DataEditor } from "./Editor";
import { Badge } from "@/components/ui/badge";
import _ from "lodash";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import CreateTableModal from "./CreateTableModal";
import DeleteTableModal from "./DeleteTableModal";
import CreateColumnModal from "./CreateColumnModal";
import DeleteColumnModal from "./DeleteColumnModal";
import { newColumn, newTable } from "@/lib/baseData";
import { post, sOptions } from "@/lib/rest";
import { useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { getIconByFieldType } from "./form-ui";
import { sfetch } from "@themarblejar/stark-fetch";
import { cellReadOnlyRenderer } from "./cell-renderer";
import { CellChange, TableChange } from "@/interface/base";
import { getTable } from "@/lib/utils";

enum ModalType {
  None = "None",
  CreateTable = "CreateTable",
  EditTable = "EditTable",
  DeleteTable = "DeleteTable",
  CreateColumn = "CreateColumn",
  EditColumn = "EditColumn",
  DeleteColumn = "DeleteColumn"
}

const DatabaseTable = () => {
  const { config, setConfig, oldConfig, project, fetch } = useDatabaseConfig();
  const { projectId } = useParams();

  const [isEditorMode, setIsEditorMode] = useState(false);
  const [script, setScript] = useState<any>("");
  const [selectedTable, setSelectedTable] = useState<TableConfig>(config.tables[0]);
  const [selectedColumn, setSelectedColumn] = useState<Column>(newColumn().newColumn);
  const [selectedCell, setSelectedCell] = useState<any>(null);
  const [showModal, setShowModal] = useState<ModalType>(ModalType.None);
  const [syncing, setSyncing] = useState(false);
  const [changes, setChanges] = useState<CellChange[]>([]);

  useEffect(() => {
    if (config.tables.length === 1 && config.tables[0].columns.length === 0) {
      setSelectedTable(config.tables[0])
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(config, null, '\t') !== script) {
      setScript(JSON.stringify(config, null, '\t'));
    }
    if (selectedTable) {
      setSelectedTable(_.find(config.tables, { id: selectedTable.id }) || config.tables[0])
    }
  }, [config])

  const dataQuery = sfetch({ ...sOptions, neonProjectId: projectId || "" }).table(selectedTable.name).withRelation(selectedTable.columns.filter((col: Column) => col.type === "relation").map((col: Column) => col.name).join(",")).getMany({ enabled: selectedTable !== null });
  const dataMutation = sfetch({ ...sOptions, neonProjectId: projectId || "" }).table(selectedTable.name).update();

  function updateModal(modalType: ModalType, column?: Column, table?: TableConfig) {
    if (!column && !table) return;
    setShowModal(modalType);
    if (column) setSelectedColumn(column);
    if (table) setSelectedTable(table);
  }

  async function saveScript() {
    setConfig(JSON.parse(script));
    saveConfig(JSON.parse(script));
  }

  async function saveConfig(configuration: any) {
    let data = JSON.stringify({
      newConfiguration: configuration,
    });

    post({ url: `/api/project/${projectId}/configuration`, data })
      .then((response) => {
        toast({
          variant: "default",
          title: "Configuration saved successfully",
        });
        fetch(projectId);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Failed to save configuration",
          description: "Please try again later",
        });
      });
  }

  const syncDatabase = async () => {
    setSyncing(true);
    await saveConfig(config);
    try {
      await post({ url: `/api/database/${projectId}/sync`, data: {} });
      toast({
        variant: "default",
        title: "Database synced successfully",
      });
      fetch(projectId);
      setSyncing(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setSyncing(false);
    }
  };

  const updateChanges = (key: string, value: string, data: any, column: Column) => {
    const presentChange = changes.find((change) => change.key === key);

    if (presentChange) {
      if (presentChange.data[column.name] === value) {
        setChanges([...changes.filter((change) => change.key !== key)]);
      } else {
        const updateChange = { ...presentChange, value };
        setChanges([...changes.filter((change) => change.key !== key), updateChange]);
      }
    } else {
      setChanges([...changes, { key, value, data, column, table: selectedTable }]);
    }
  }

  const saveChanges = async () => {
    const tableChanges: TableChange[] = []
    changes.forEach(async (change) => {
      const hasTable = tableChanges.find((tableChange) => tableChange.tableName === change.table.name);
      if (hasTable) {
        hasTable.data[change.column.name] = change.value;
      } else {
        const { createdOn, updatedOn, ...data } = change.data;
        tableChanges.push({ tableName: change.table.name, data: { ...data, [change.column.name]: change.value } });
      }
    });

    const tableChange = tableChanges[0];
    await dataMutation.mutate(tableChange.data);

    setChanges([]);
  }

  const cancelChanges = () => {
    setChanges([]);
  }

  const closeModal = () => {
    setShowModal(ModalType.None);
  }

  const addChildRelationToColumn = (column: Column) => {
    let decoratedColumn = { ...column }
    if (column.type === "relation") {
      const childTable = getTable(config, (column.options as RelationOption).tableId);
      const childColumn = childTable.columns.filter(col => col.id === (column.options as RelationOption).columnId)[0]
      decoratedColumn.options = {
        ...decoratedColumn.options,
        backColumnName: childColumn.name,
        hasMultipleBack: (childColumn.options as RelationOption).hasMultiple
      } as any
    }

    return decoratedColumn;
  }

  function renderEditor() {
    return <DataEditor
      configuration={config}
      script={script}
      setScript={setScript}
    />
  }

  function renderColumnHeader(key: string, name: string, type: string, column?: Column) {
    return <Popover key={`${key}`}>
      <TableHead
        key={name}
        className="h-10 w-40 items-center border-r p-3 text-black"
      >
        <div className="flex items-center justify-between">
          {" "}
          <div className="flex items-center">
            <div className="mr-2 w-fit">
              {" "}
              {getIconByFieldType(type)}
            </div>
            <>{name}</>
          </div>
          <PopoverTrigger>
            {" "}
            <>
              {" "}
              <ChevronDown className="h-4 w-4" />
            </>
          </PopoverTrigger>
        </div>
      </TableHead>
      <PopoverContent className="w-[200px] p-2 font-normal">
        {name !== "id" && name !== "createdOn" && name !== "updatedOn" &&
          (
            <>
              <Button
                variant="ghost"
                className="flex w-full items-center justify-start"
                onClick={() => updateModal(ModalType.EditColumn, column)}
              >
                <Pencil className="mr-2 h-4 w-4" />
                <>Edit</>
              </Button>{" "}
            </>
          )}
        <Button
          variant="ghost"
          className="flex w-full items-center justify-start"
          onClick={() => { }}
        >
          <ArrowUpNarrowWide className="mr-2 h-4 w-4" />
          <>Sort Ascending</>
        </Button>
        <Button
          variant="ghost"
          className="flex w-full items-center justify-start"
          onClick={() => { }}
        >
          <ArrowDownNarrowWide className="mr-2 h-4 w-4" />
          <>Sort Descending</>
        </Button>{" "}
        {name !== "id" && name !== "createdOn" && name !== "updatedOn" && (
          <>
            <Separator />
            <Button
              variant="ghost"
              className="flex w-full items-center justify-start text-red-600"
              onClick={() => updateModal(ModalType.DeleteColumn, column)}
            >
              <Trash2 className="mr-2 h-4 w-4" />

              <>Delete</>
            </Button>
          </>
        )}
      </PopoverContent>
    </Popover>
  }

  function renderCell(key: string, value: string, data: any, column: Column | null) {
    const selected = column && selectedCell && selectedCell.key === key;
    const changed = changes.find((change) => change.key === key);
    const cellValue = changed ? changes.find((change) => change.key === key)?.value : value;
    return (
      <TableCell
        key={key}
        onClick={() => {
          setSelectedCell({ key, column });
        }
        }
        className={`h-10 ${selected ? "border-2 border-green-300" : "border-r"} ${changed && "bg-green-200"} p-3`}
      >
        {selected && column ?
          <div className="flex justify-start w-40 truncate">
            <span>
              <input
                className="w-full bg-transparent focus:outline-none"
                value={cellValue}
                onChange={(e) => {
                  updateChanges(key, e.target.value, data, column,);
                }}
              />
            </span>
          </div> :
          <div className="flex justify-start w-40 truncate">
            <span>
              {cellReadOnlyRenderer(cellValue, column)}
            </span>
          </div>}
      </TableCell>
    )
  }

  console.log(config)

  return (
    <>
      <div className="w-screen p-4 min-h-[calc(100vh-48px)]">
        <div className="flex min-w-[1060px] items-center justify-between">
          <div className="flex items-center">
            <div className="mr-4 flex items-center space-x-2">
              <Switch
                id="editor-mode"
                checked={isEditorMode}
                onCheckedChange={() => setIsEditorMode(!isEditorMode)}
              />
              <Label htmlFor="editor-mode">Editor Mode</Label>
            </div>
            {!syncing ? (
              <Button
                variant={"secondary"}
                className="mr-4 bg-green-300"
                onClick={() => syncDatabase()}
              >
                Sync Database
              </Button>
            ) : (
              <Button
                disabled
                className="mr-4 bg-green-300 text-slate-900"
              >
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Syncing
              </Button>
            )}
            <Button className="mr-4" onClick={() => { }}>
              Fetch Data
            </Button>
            {isEditorMode && <Button
              variant={"default"}
              onClick={() => saveScript()}
              className="mr-4"
            >
              Save Configuration
            </Button>}
            {!isEditorMode && <Button
              variant={"default"}
              onClick={() => saveConfig(config)}
              className="mr-4"
            >
              Save Configuration
            </Button>}
            {changes.length > 0 && <Button className="mr-4 bg-green-300" onClick={saveChanges}>
              Save Changes ({changes.length})
            </Button>}
            {changes.length > 0 && <Button variant={"destructive"} className="mr-4" onClick={cancelChanges}>
              Cancel Changes
            </Button>}
            <div className="flex flex-col items-start">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="font-semibold">{project.name}</div>
                  </TooltipTrigger>
                  <TooltipContent>Project Name</TooltipContent>
                </Tooltip>
                <div className="font-mono text-xs text-gray-600">{ }</div>
              </TooltipProvider>
            </div>
          </div>
        </div>
        {!isEditorMode &&
          <div className="flex h-full">
            <Card className="mt-6 mr-6 min-w-60 h-[calc(100vh-152px)] overflow-y-scroll">
              <CardHeader>
                <CardTitle>Tables</CardTitle>
              </CardHeader>
              <CardContent className="p-4 pt-0">
                <nav
                  className="grid gap-4 text-sm text-muted-foreground"
                >
                  {_.sortBy(config.tables, ['order', 'name']).map((table: TableConfig, index: number) => (
                    <div key={`${index}tablenav`} className="flex text-left cursor-pointer justify-between items-center" onClick={() => {
                      setSelectedTable(table);
                      setChanges([]);
                    }}>
                      <p className={`${table.id === selectedTable.id ? "bg-green-300 px-2 py-1 rounded-md" : "text-neutral-900 px-2 py-1 rounded-md"}`}>{table.name}</p>
                      <div className="flex justify-center items-center">
                        {oldConfig.tables.find((badgeTable) =>
                          _.isEqual(badgeTable, table),
                        ) ? (
                          <></>
                        ) : (
                          <Badge
                            variant={"outline"}
                            className="ml-3 text-slate-400 h-6"
                          >
                            draft
                          </Badge>
                        )}
                        <Button
                          variant="ghost"
                          className="flex items-center justify-start p-1"
                          onClick={() => updateModal(ModalType.EditTable, undefined, table)}
                        >
                          <Pencil className="h-4 w-6" />
                        </Button>
                        <Button
                          variant="ghost"
                          className="flex items-center justify-start text-red-600 p-1"
                          onClick={() => setShowModal(ModalType.DeleteTable)}
                        >
                          <Trash2 className="h-4 w-6" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </nav>
                <Button
                  variant="outline"
                  size={"icon"}
                  className="h-8 w-8 p-0 mt-6"
                  onClick={() => setShowModal(ModalType.CreateTable)}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
            <div className="mt-6 relative rounded-lg border border-slate-300 overflow-x-scroll">
              <Table className="w-full rounded-t-lg text-[14px] font-medium text-black h-[calc(100vh-182px)] overflow-y-scroll">
                <TableHeader className="relative h-[42px] w-full bg-slate-100">
                  <TableRow className="relative">
                    {renderColumnHeader(`${selectedTable.name}.id`, "id", "uuid")}
                    {_.sortBy(selectedTable.columns, ['order']).map((column: Column, index: number) => (
                      renderColumnHeader(`${selectedTable.name}.${column.name}`, column.name, column.type, column)
                    ))}
                    {renderColumnHeader(`${selectedTable.name}.createdOn`, "createdOn", "date")}
                    {renderColumnHeader(`${selectedTable.name}.updatedOn`, "updatedOn", "date")}
                    <TableHead className="h-[30px] w-fit border-r px-2 text-black">
                      <Button
                        variant={"ghost"}
                        className="m-auto h-8 w-8 p-px hover:bg-slate-200"
                        onClick={() => setShowModal(ModalType.CreateColumn)}
                      >
                        <Plus className="h-4 w-4 cursor-pointer rounded-lg p-0" />
                      </Button>
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className="h-fit">
                  {dataQuery.isSuccess && dataQuery.data && dataQuery.data.data.length > 0 && dataQuery.data.data.map((rowData: any) => (
                    <TableRow className="h-11">
                      {renderCell(`${selectedTable.name}.id`, rowData.id, rowData, null)}
                      {_.sortBy(selectedTable.columns, ['order']).map((column: Column) => (
                        renderCell(`${selectedTable.name}.${column.name}.${rowData.id}`, rowData[column.name], rowData, column)
                      ))}
                      {renderCell(`${selectedTable.name}.createdOn`, rowData.createdOn, rowData, null)}
                      {renderCell(`${selectedTable.name}.updatedOn`, rowData.updatedOn, rowData, null)}
                    </TableRow>
                  ))}
                  <TableRow />
                </TableBody>
              </Table>
            </div>
          </div>
        }
        {isEditorMode && (
          renderEditor()
        )}
      </div>
      {
        showModal === ModalType.CreateTable && (
          <CreateTableModal
            table={newTable().newTable}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.EditTable && (
          <CreateTableModal
            table={{...selectedTable, columns: [...selectedTable.columns.map(column => addChildRelationToColumn(column))]}}
            closeAction={closeModal}
            isEditMode={true}
          />
        )
      }
      {
        showModal === ModalType.DeleteTable && (
          <DeleteTableModal
            table={selectedTable}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.CreateColumn && (
          <CreateColumnModal
            table={selectedTable}
            column={newColumn().newColumn}
            closeAction={closeModal}
          />
        )
      }
      {
        showModal === ModalType.EditColumn && (
          <CreateColumnModal
            table={{...selectedTable, columns: [...selectedTable.columns.map(column => addChildRelationToColumn(column))]}}
            column={addChildRelationToColumn(selectedColumn)}
            closeAction={closeModal}
            isEditMode={true}
          />
        )
      }
      {
        showModal === ModalType.DeleteColumn && (
          <DeleteColumnModal
            table={selectedTable}
            column={selectedColumn}
            closeAction={closeModal}
          />
        )
      }
    </>
  );
};

export default DatabaseTable;
