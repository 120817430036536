import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Plus } from "lucide-react";
import DatabaseTable from "../components/database/DatabaseTable";
import { Button } from "@/components/ui/button";
import Loading from "@/custom/Loading/loading";
import _ from "lodash";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import { newTable } from "@/lib/baseData";

const Data = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  
  const { config, setConfig, project, fetch, loading } = useDatabaseConfig();

  useEffect(() => {
    fetch(projectId)
  }, []);

  const addInitialTable = () => {
    const { newTable: table } = newTable()
    setConfig({...config, tables: [table]})
  }

  return (
    <div className="h-full w-full overflow-hidden">
      {config.tables.length > 0 ? (
        <DatabaseTable />
      ) : (
        <>
          {!loading ? (
            <div className="flex h-screen w-full flex-col items-center justify-center space-y-2">
              <div className="flex flex-col items-center space-y-6">
                <div>
                  {" "}
                  {project && (
                    <div className="mb-3 text-2xl font-semibold leading-none tracking-tight">
                      {project?.name}
                    </div>
                  )}
                  <p className="text-sm text-slate-500">No tables available </p>
                </div>
                <div className="flex space-x-4">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Back to Projects
                  </Button>
                  <Button
                    variant="default"
                    onClick={() => {
                      addInitialTable();
                    }}
                  >
                    <Plus className="mr-2 h-4 w-4" /> Create Table
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-screen items-center justify-center">
              <Loading />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Data;
