import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import Logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

export function SignUp() {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate("/home");
  };

  return (
    <div className="h-screen w-full text-slate-900 lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[468px] gap-6 rounded-md border border-slate-300 p-8">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Sign Up</h1>
            <p className="text-muted-foreground text-balance text-slate-400">
              Enter your information to create an account
            </p>
          </div>
          <div className="grid w-[332px] gap-4 justify-self-center">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="firstName" className="text-left">
                  First Name
                </Label>
                <Input
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="lastName" className="text-left">
                  Last Name
                </Label>
                <Input
                  id="emlastNameil"
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email" className="text-left">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password" className="text-left">
                Password
              </Label>
              <Input id="password" type="password" placeholder="" required />
            </div>
            <Button
              type="button"
              className="w-full border-slate-200 bg-slate-900"
              onClick={onSubmit}
            >
              Login
            </Button>
          </div>
          <div className="mt-4 w-[332px] justify-self-center text-center text-sm text-slate-900">
            Already have an account?{" "}
            <a href="/" className="underline">
              Sign in
            </a>
          </div>
        </div>
      </div>
      <div className="flex h-full items-center justify-center bg-slate-900">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 50, width: "auto" }}
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
