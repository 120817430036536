import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

import CreateProjectModal from "@/components/project/CreateProjectModal";
import { toast } from "@/components/ui/use-toast";
import { get, post, del } from "@/lib/rest";
import Logo from "../assets/logo.svg";
import { Trash } from "lucide-react";
import Loading from "@/custom/Loading/loading";
import { useAuth } from "@/hooks/auth/AuthContext";

interface Project {
  id: number;
  name: string;
  organizationId: number;
}

export function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated }: any = useAuth();
  
  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated("unauthenticated");
    navigate("/");
  };

  const [newProjectModal, setNewProjectModal] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await get({ url: "/api/project" });
      setProjects(response.data.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const addProject = (projectName: string, regionString: string) => {
    setLoading(true);
    const projectExists = projects.some(
      (project) => project.name === projectName,
    );

    if (projectExists) {
      setLoading(false);
      toast({
        variant: "destructive",
        title: "Please use a different project name.",
        description: "A project with this name already exists.",
      });

      return;
    }

    let data = JSON.stringify({
      name: projectName,
      region: regionString
    });

    post({ url: "/api/project", data })
      .then((response) => {
        fetchProjects();
        setNewProjectModal(false);
        toast({
          variant: "default",
          title: "Project created successfully",
        });
        // console.log(response);
        navigate(`/project/${response.data.projectId}`, {
          state: {
            projectId: response.data.projectId,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast({
          variant: "destructive",
          title: "Failed to create project",
          description: "Please try again later",
        });
      });
  };
  
  const deleteProject = (projectId: number) => {
    setLoading(true);
    del({ url: `/api/project/${projectId}`, data: {} }) // API WPI changed later
      .then((response) => {
        fetchProjects();
        toast({
          variant: "default",
          title: "Project deleted successfully",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast({
          variant: "destructive",
          title: "Failed to delete project",
          description: "Please try again later",
        });
      });
  };

  return (
    <div className="h-screen w-full overflow-hidden bg-slate-900 lg:min-h-[600px] xl:min-h-[800px]">
      <nav className="flex w-full items-center justify-between p-8">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 15, width: "auto" }}
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
        <Button
          variant="destructive"
          className=""
          onClick={() => {
            logout();
          }}
        >
          Sign Out
        </Button>
      </nav>
      {!loading ? (
        <div className="grid w-full grid-cols-1 justify-start gap-6 p-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          <div
            className="flex h-[180px] w-full min-w-[180px] cursor-pointer items-center justify-center rounded-md border border-slate-300 hover:bg-slate-700"
            onClick={() => setNewProjectModal(true)}
          >
            <p className="text-slate-300">+ New Project</p>
          </div>
          {projects?.length > 0 &&
            projects.map((project: any, index: number) => (
              <div
                key={project.id}
                onClick={() => {
                  navigate(`/project/${project.id}`, {
                    state: {
                      projectId: project.id,
                    },
                  });
                }}
                className="relative z-0 flex h-[180px] w-full min-w-[180px] cursor-pointer items-center justify-center rounded-md border border-slate-300 hover:bg-slate-700"
              >
                <Button
                  variant="ghost"
                  className="absolute right-2 top-2 z-10 text-red-400"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteProject(project.id);
                  }}
                >
                  <Trash className="h-4 w-4" />
                </Button>

                <p className="text-slate-300">{project.name}</p>
              </div>
            ))}
        </div>
      ) : (
        <Loading />
      )}
      {newProjectModal && (
        <CreateProjectModal
          setNewProjectModal={setNewProjectModal}
          addProject={addProject}
          loading={loading}
        />
      )}
    </div>
  );
}
