import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated }: any = useAuth();

  if (isAuthenticated === "unauthenticated") {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
