import { DatabaseConfig } from "@/interface/config";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { v7 as uuidv7 } from "uuid";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getTable(config: DatabaseConfig, tableId: string) {
  return config.tables.filter(table => table.id == tableId)[0];
}

export const uuid = (): string => uuidv7();  
